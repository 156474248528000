<template>
<div id="app" class="privacy-policy page-template-default page page-id-3 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://whatsplusapk.com/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://whatsplusapk.com/" rel="home">
            GB WhatsApp App
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://whatsplusapk.com/" aria-current="page">GB WhatsApp</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://whatsplusapk.com/gbwhatsapp-apk-download/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://whatsplusapk.com/disclaimer/">Disclaimer</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article id="post-3" class="post-3 page type-page status-publish">
            <div class="inside-article">
              <header class="entry-header" aria-label="Content">
                <h1 class="entry-title center" itemprop="headline">
                  Blogs
                </h1>
              </header>

              <div class="blog-list-row">
                <a v-for="(data, i) in reverseDoc" :key="i" class="blog-list-item" :href="'https://whatsplusapk.com/blogs'+data.url"
                  >
                  <div class="blog-list-img">
                    <img :src="data.poster" :alt="data.alt">
                  </div>
                  <div class="blog-list-content">
                    <div class="blog-list-title">
                      {{ data.title }}
                    </div>
                    <div class="blog-list-descript">
                      {{ data.desc }}
                    </div>
                    <div class="blog-list-date">
                      <!-- {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }} -->
                      {{ data.date }}
                    </div>
                  </div>
                </a>
              </div>

              <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://whatsplusapk.com/blogs/">Blog</a>
          |
          <a href="https://whatsplusapk.com/disclaimer/">Disclaimer</a>
          |
          <a href="https://whatsplusapk.com/about-us/">About Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://whatsplusapk.com/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';
import doc from '@/utlis/blogList.js';

export default {
  name: 'Disclaimer',
  data() {
    return {
      doc: doc,
      pageName: 'blogs',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
    };
  },

  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }
  },
  computed: {
    reverseDoc () {
      return this.doc.blogList.slice().reverse();
    },
  },
  methods: {
    downloadURL() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },

    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
  },
};
</script>
